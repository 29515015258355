<template>
  <div>
    <b-modal
      id="modalStockAvailable"
      hide-footer
      centered
      size="lg"
      v-model="modalStockAvailableShow"
    >
      <b-row>
        <b-col cols="12">
          <div class="my-2">
            <p class="h1 text-center">Incidencia</p>
            <p class="text-center" style="margin-top: 30px;">
              Los siguientes productos han sido reservados por otro comercial.
            </p>

            <p
              class="text-center"
              v-for="pr in productsUnavailable"
              :key="pr.idArticulo"
            >
              - {{ pr.articulo }}
            </p>

            <p class="text-center">
              ¿Desea continuar con el envío del pedido?
            </p>

            <div style=" display: flex;justify-content: space-around;">
              <!--  <div class="mt-2 d-flex justify-content-center"> -->
              <b-button variant="outline-primary" @click="buttonCancelClicked">
                Cancelar
              </b-button>
              <b-button variant="primary" @click="buttonContinueClicked">
                Continuar
              </b-button>
              <!--  </div> -->
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BInputGroup,
  BRow,
  BCol,
  VBModal,
  BProgress,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters, mapMutations } from "vuex";
import store from "@/store";

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
    BProgress,
    BFormCheckbox,
  },

  data() {
    return { status: "not_accepted", accepted: false };
  },
  computed: {
    modalStockAvailableShow: {
      get() {
        return this.$store.getters["orders/getShowModalStockAvailable"];
      },
      set(val) {
        this.$store.commit("orders/setShowModalStockAvailable", val);
      },
    },
    ...mapGetters({
      productsUnavailable: "orders/getProductsUnavailable",
    }),
  },

  created: function() {},
  methods: {
    ...mapActions({
      createOrder: "orders/createOrder",
    }),
    buttonCancelClicked() {
      this.$store.commit("orders/setShowModalStockAvailable", false);
    },
    buttonContinueClicked() {
      console.log(this.$parent);
      this.$store.commit("orders/setShowModalStockAvailable", false);
      this.createOrder();
    },
  },
  watch: {
    modalStockAvailableShow() {},
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>
