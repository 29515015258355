<template>
  <div class="ecommerce-application">
    <div class="list-view">
      <b-row v-if="!cart || !cart.lineas || !cart.lineas.length">
        <b-col cols="12">
          <b-card>
            <div class="text-center my-3">
              <h1 class="mb-2">{{ $i18n.messages.CarritoVacio }}</h1>
              <router-link :to="{ name: 'products' }">
                <b-button variant="primary" size="lg">
                  {{ $i18n.messages.VerProductos }}
                </b-button>
              </router-link>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="cart && cart.lineas && cart.lineas.length">
        <b-col cols="12">
          <div class="checkout-items">
            <b-card
              v-for="linea in cart.lineas"
              :key="linea.CODART"
              class="ecommerce-card"
              no-body
            >
              <!-- Product Details: Card Body -->
              <b-card-body>
                <div class="item-name">
                  <h6 class="mb-0">
                    <span class="text-body">
                      {{ linea.CODART }} - {{ linea.NOMBRE }}
                    </span>
                  </h6>
                  <span class="item-company">-</span>
                </div>

                <p v-if="linea.UNITMEAS == 'pcs'">
                  <b>Piezas:</b>
                  {{
                    Number(linea.CANTIDAD).toLocaleString("de-DE", {
                      maximumFractionDigits: 4,
                    })
                  }}
                </p>
                <p v-if="linea.UNITMEAS == 'm2'">
                  <b>{{ $i18n.messages.SuperficieM2 }}:</b>
                  {{
                    Number(linea.CANTIDAD).toLocaleString("de-DE", {
                      maximumFractionDigits: 4,
                    })
                  }}
                </p>

                <p>
                  <b>{{ $i18n.messages.weight }}:</b>
                  {{
                    Number(linea.PESO).toLocaleString("de-DE", {
                      maximumFractionDigits: 4,
                    })
                  }}
                </p>
              </b-card-body>

              <!-- Product Options/Actions -->
              <div class="item-options text-center">
                <div class="item-wrapper">
                  <div class="item-cost">
                    <h4 class="item-price">
                      <b>{{ $i18n.messages.Price }}:</b>
                      {{
                        Number(linea.AMOUNT).toLocaleString("de-DE", {
                          maximumFractionDigits: 4,
                        })
                      }}
                      €
                    </h4>
                  </div>
                </div>
                <div class="item-cost">
                  <h5 class="item-price" style="margin-top:10px">
                    <b>{{ $i18n.messages.Tasa }}:</b>
                    {{
                      Number(linea.TASA).toLocaleString("de-DE", {
                        maximumFractionDigits: 4,
                      })
                    }}
                    €
                  </h5>
                </div>
                <b-button
                  variant="primary"
                  style="margin-top: 20px"
                  @click="openEdit(linea)"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>{{ $i18n.messages.Modificar }}</span>
                </b-button>
                <b-button
                  variant="primary"
                  style="margin-top: 20px"
                  @click="removeProductFromCartClickAsk(linea)"
                >
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span>{{ $i18n.messages.Eliminar }}</span>
                </b-button>
              </div>
            </b-card>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="checkout-options">
            <b-card>
              <b-row>
                <b-col cols="12" lg="6">
                  <h4>
                    <b>{{ $i18n.messages.TotalPrice }}:</b>
                    {{
                      Number(totalAmount).toLocaleString("de-DE", {
                        maximumFractionDigits: 2,
                      })
                    }}
                    €
                    <b>{{ $i18n.messages.Taxes }}</b>
                  </h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" lg="6"  style="margin-top:10px">
                  <p for="comment">
                    <b class="pb-1">{{ $i18n.messages.ref }}</b>
                    <b-form-input id="ref" v-model="ref" type="search" />
                  </p>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" lg="6">
                  <p for="comment">
                    <b>{{ $i18n.messages.DeliveryMethod }}</b>
                  </p>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio
                      v-model="selected"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="PICKUP"
                      class="mb-1"
                      >{{ $i18n.messages.Pickup }}</b-form-radio
                    >
                    <b-form-radio
                      v-model="selected"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="AGENCY"
                      class="mb-1"
                      >{{ $i18n.messages.Agency }}</b-form-radio
                    >
                    <b v-if="selected == 'AGENCY'">{{
                      $i18n.messages.DeliveryAddress
                    }}</b>
                    <b-form-select
                      id="deliverySelect"
                      v-model="delivery"
                      value-field="address_no"
                      text-field="address"
                      :options="deliveryAddresses"
                      class="w-70 mb-1"
                      v-if="selected == 'AGENCY'"
                    />
                    <b-form-radio
                      v-model="selected"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="REGGIO"
                      class="mb-1"
                    >
                      {{ $i18n.messages.Reggio }}</b-form-radio
                    >
                  </b-form-group>
                </b-col>
                <!--   <b-col cols="12" lg="6" style="margin-top:10px">
                  <p for="comment">
                    <b>{{ $i18n.messages.BillingAddress }}</b>
                  </p>
                  <b-form-select
                    id="billingSelect"
                    v-model="billing"
                    value-field="ADDRESS_ID"
                    text-field="ADDRESS_LOV"
                    :options="billingAddresses"
                    class="w-80"
                    disabled
                  />
                </b-col> -->
              </b-row>

              <!--   <p>Value: '{{ format_date(fecha) }}'</p> -->

              <p
                for="comment"
                class="text-danger"
                style="margin-top: 30px"
                v-if="selected == 'DELIVERY_TO'"
              >
                {{ $i18n.messages.Mandatory }}
              </p>
              <p for="comment" style="margin-top: 30px" v-else>
                {{ $i18n.messages.Comentario }}
              </p>
              <b-form-textarea
                id="comment"
                :placeholder="$i18n.messages.ComentarioPlaceholder"
                rows="3"
                v-model="comment"
                required
              />

              <b-button
                variant="primary"
                size="lg"
                class="w-100 mt-2"
                @click="creaOrder()"
                :disabled="
                  selected == '' ||
                    (selected === 'AGENCY' && delivery == null) ||
                    (selected === 'DELIVERY_TO' && comment === '')
                "
              >
                <b-spinner
                  v-if="disableBtn || billing == {} || delivery == {}"
                  small
                ></b-spinner>
                {{ $i18n.messages.ENVIARPEDIDO }}
              </b-button>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <b-modal ref="my-modal" hide-footer>
        <div class="d-block text-center">
          <b-row v-if="successAdd">
            <b-col cols="12">
              <div class="my-2">
                <p class="h1 text-center">
                  {{ $i18n.messages.OKOrder }}
                </p>
                <div class="mt-2 d-flex justify-content-center">
                  <a :href="$router.resolve({ name: 'products' }).href">
                    <b-button variant="secondary" class="ml-2">
                      {{ $i18n.messages.Accept }}
                    </b-button>
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="errorAdd">
            <b-col cols="12">
              <div class="my-2">
                <p class="h1 text-center">
                  {{ $i18n.messages.ErrorAddOrder }}
                </p>
                <div class="mt-2 d-flex justify-content-center">
                  <b-button variant="secondary" class="ml-2" @click="hideModal">
                    {{ $i18n.messages.Accept }}
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </div>

    <!--     <modal-edit-product
      :product="productAdd"
      :quantityPales="quantityPales"
      :quantityCajas="quantityCajas"
      :idCarrito="idCarrito"
      :idLinea="idLinea"
      v-if="productAdd && Object.keys(productAdd).length"
    />
    <modal-stock-available></modal-stock-available> -->

    <b-modal ref="modalRemove" hide-footer>
      <div class="my-2">
        <p class="h1 text-center">
          {{ $i18n.messages.WantRemove }}
        </p>
        <div class="mt-2 d-flex justify-content-center">
          <b-button variant="secondary" class="ml-2" @click="hideModalRemove">
            {{ $i18n.messages.Cancel }}
          </b-button>
          <b-button
            variant="primary"
            class="ml-2"
            @click="removeProductFromCartClick"
          >
            {{ $i18n.messages.Accept }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal ref="my-modalEdit" modal-class="modal-fullscreen" hide-footer>
      <Product
        v-if="lineaPressed"
        :idProd="lineaPressed.CODART"
        :unitsRec="lineaPressed.UNITMEAS"
        :quantityPalesRec="lineaPressed.CAJASPALET"
        :quantityCajasRec="lineaPressed.CAJASTOTALES"
        :quantityPiezasRec="lineaPressed.METROSCAJA"
      ></Product>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BCardBody,
  BFormTextarea,
  BBadge,
  BSpinner,
  BCalendar,
  BFormDatepicker,
  BFormSelect,
  BFormGroup,
  BFormRadio,
    BFormInput,
} from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalEditProduct from "@/views/ModalEditProduct.vue";
import moment from "moment";
import ModalStockAvailable from "@/views/ModalStockAvailable.vue";
import Product from "@/views/product.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormTextarea,
    BCardBody,
    BBadge,
    BSpinner,
    ModalEditProduct,
    BCalendar,
    BFormDatepicker,
    ModalStockAvailable,
    BFormSelect,
    Product,
    BFormGroup,
    BFormRadio,
    BFormInput,
  },
  data() {
    return {
      modal: true,
      comment: "",
      quantityPales: 0,
      quantityCajas: 0,
      disableBtn: false,
      billing: null,
      delivery: null,
      totalAmount: 0.0,
      lineaPressed: null,
      selected: "",
      ref: "",
    };
  },
  watch: {
    selected() {
      this.delivery = null;
    },
  },
  created() {
    var username = "";
    var idClient = "";
    this.setSuccessAdd(false);

    console.log("client: ", this.currentCustomer);
    if (this.currentCustomer) {
      idClient = this.currentCustomer.Cliente;
    }
    console.log("IDUSER: ", this.user);
    username = this.user.usuario_id;

    if (this.user.rol_id === 2) {
      username = this.user.usuario_id;
      idClient = "ADMIN";
    }

    /*  this.searchFamilies(null);
    this.searchSizes(null); */

    if (username.toString() != "" && idClient.toString() != "") {
      this.setLoading(true);
      this.getCartOrCreate({
        username: username.toString(),
        idClient: idClient.toString(),
      }).then(() => {
        if (this.cart.lineas != null && this.cart.lineas != undefined) {
          this.cart.lineas.forEach((element) => {
            this.totalAmount += Number(element.AMOUNT);
          });
          this.cart.lineas.forEach((element) => {
            this.totalAmount += Number(element.TASA);
          });
        }
        console.log(this.billingAddresses);
        if (this.billingAddresses.length > 0) {
          this.billing = this.billingAddresses[0].ADDRESS_ID;
        }

        this.setLoading(false);
      });
    }
  },
  computed: {
    ...mapGetters({
      cart: "carts/getCart",
      user: "auth/getUser",
      deliveryAddresses: "carts/getDelivery",
      billingAddresses: "carts/getBilling",
      productAdd: "products/getProductAdd",
      currentCustomer: "customers/getCurrentCustomer",
      successAdd: "orders/getSuccessAdd",
      errorAdd: "orders/getErrorAdd",
    }),
  },
  methods: {
    showModalRemove() {
      this.$refs["modalRemove"].show();
    },
    hideModalRemove() {
      this.$refs["modalRemove"].hide();
    },
    openEdit(linea) {
      console.log(linea);
      this.lineaPressed = linea;
      this.$refs["my-modalEdit"].show();
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYYMMDD");
      }
    },
    ...mapActions({
      getCartOrCreate: "carts/getCartOrCreate",
      createOrder: "orders/createOrder",
      removeLine: "carts/removeLine",
      updateLine: "carts/updateLine",
      getProductAdd: "products/getProductAdd",
    }),
    ...mapMutations({
      setShowModalAdd: "products/setShowModalAdd",
      setShowModalStockAvailable: "orders/setShowModalStockAvailable",
      setLoading: "app/setLoading",
      setSuccessAdd: "orders/setSuccessAdd",
      setErrorAdd: "orders/setErrorAdd",
      recoverCurrentCustomer: "customers/recoverCurrentCustomer",
      setCurrentCustomer: "customers/setCurrentCustomer",
    }),
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    removeProductFromCartClickAsk(linea) {
      this.lineaPressed = linea;
      this.showModalRemove();
    },
    removeProductFromCartClick() {
      this.removeLine(this.lineaPressed.IDLINEA);
      this.hideModalRemove();
    },
    openModalProduct(linea) {
      this.quantityPales = Math.floor(linea.cantidad / linea.cajasPalet);
      this.quantityCajas = linea.cantidad % linea.cajasPalet;
      this.idCarrito = this.cart.idCarrito;
      this.idLinea = linea.idLinea;
      this.getProductAdd(linea.codArt);
      this.setShowModalAdd(true);
    },
    creaOrder() {
      // var setShowModalStockAvailable = this.setShowModalStockAvailable;
      //   this.disableBtn = true;
      // var fechaString = this.format_date(this.fecha);

      if (this.currentCustomer === "" || this.currentCustomer === null) {
        this.recoverCurrentCustomer();
        /*  this.modalShow = true; */
      }
      const obj = {
        cliente: this.currentCustomer.Cliente,
        comment: this.comment,
        delivery: this.selected,
        shippAddr: this.delivery === null ? "" : this.delivery,
        lineas: this.cart.lineas,
        idCarrito: this.cart.IDCARRITO,
        ref: this.ref,
      };
      this.setLoading(true);
      this.createOrder(obj).then(() => {
        this.showModal();
        this.setLoading(false);
      });
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
